import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

function HeaderFooter({children}){
    let navigate = useNavigate();

    return(
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                padding: '20px',
                textAlign: 'center',
                zIndex: '1'
                }}>
                <Button onClick={() => navigate("/", { replace: true })} style={{color: '#fff'}}>home</Button>
                { localStorage.getItem("token") &&
                    <Button onClick={() => {
                        localStorage.removeItem("token")
                        localStorage.removeItem("expiration")
                        localStorage.removeItem("lineupData")
                        localStorage.removeItem("data")
                        localStorage.removeItem("lineup")
                        navigate("/", { replace: true })
                        window.location.reload()
                    }} style={{color: '#fff'}}>logout</Button>
                }
                </Box>
                {children}
            <Box sx={{
                marginTop: 'auto',
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                padding: '20px',
                textAlign: 'center',
                zIndex: '1'
                }}>Lineup Lens: thanks for trying out the tool!</Box>
        </Box>
    )
}

export default HeaderFooter;