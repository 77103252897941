import React from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button } from '@mui/material';
import { SvgIcon } from "@material-ui/core";
import './styles/generatedBG.css';
import MusicNote from './assets/musical-note.svg';
import backgroundVideo from './assets/IMG-0674.mp4';

const authEndpoint = 'https://accounts.spotify.com/authorize';
const redirectUri = 'https://lineuplens.com/lineups';
// const redirectUri = 'http://localhost:8888/lineups'
const client_id = '74fedf76b5ea469d936cdae4bf6ebca9'; // Your client id
let scopes = 'user-read-recently-played user-library-read user-top-read user-follow-read playlist-read-private';

const loginUrl = `${authEndpoint}?
client_id=${client_id}
&redirect_uri=${redirectUri}
&scope=${scopes}
&response_type=token
&show_dialog=true`

function SpoootifyIcon(){
    return(
        <SvgIcon style={{transform: 'scale(3)', margin: '11px 10px 4px 4px', paddingTop: '1px'}}>
            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="20px" height="20px">    <path d="M15,3C8.4,3,3,8.4,3,15s5.4,12,12,12s12-5.4,12-12S21.6,3,15,3z M19.731,21c-0.22,0-0.33-0.11-0.55-0.22 c-1.65-0.991-3.74-1.54-5.94-1.54c-1.21,0-2.53,0.22-3.63,0.44c-0.22,0-0.44,0.11-0.55,0.11c-0.44,0-0.77-0.33-0.77-0.77 s0.22-0.77,0.66-0.77c1.43-0.33,2.861-0.55,4.401-0.55c2.53,0,4.84,0.66,6.82,1.76c0.22,0.22,0.44,0.33,0.44,0.77 C20.39,20.78,20.06,21,19.731,21z M20.94,17.921c-0.22,0-0.44-0.11-0.66-0.22c-1.87-1.21-4.511-1.87-7.37-1.87 c-1.43,0-2.751,0.22-3.74,0.44c-0.22,0.11-0.33,0.11-0.55,0.11c-0.55,0-0.881-0.44-0.881-0.881c0-0.55,0.22-0.77,0.77-0.991 c1.32-0.33,2.641-0.66,4.511-0.66c3.08,0,5.94,0.77,8.361,2.2c0.33,0.22,0.55,0.55,0.55,0.881 C21.82,17.48,21.491,17.921,20.94,17.921z M22.37,14.4c-0.22,0-0.33-0.11-0.66-0.22c-2.2-1.21-5.39-1.98-8.47-1.98 c-1.54,0-3.19,0.22-4.621,0.55c-0.22,0-0.33,0.11-0.66,0.11c-0.66,0.111-1.1-0.44-1.1-1.099s0.33-0.991,0.77-1.1 C9.39,10.22,11.26,10,13.24,10c3.41,0,6.93,0.77,9.681,2.2c0.33,0.22,0.66,0.55,0.66,1.1C23.471,13.96,23.03,14.4,22.37,14.4z"/></svg>
        </SvgIcon>
    )
}

function Landing() {
    const mobile = useMediaQuery('(max-width:850px)');

    return(
        <Box className='landingBg' sx={{
            fontFamily: 'Oswald, sand-serif',
            display: 'flex',
            color: 'white',
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            top: '0',
            bottom: '0',
            }}
            >
            <video style={{
                position: 'absolute',
                zIndex: '2',
                filter: 'brightness(55%)',
                minWidth: '100%',
                minHeight: '100%',
                width: 'auto',
                height: 'auto'
        
            }} autoPlay loop muted>
                <source src={backgroundVideo} type="video/mp4"/>
            </video>
            <Box sx={{
                position: 'absolute',
                top: '50px',
                right: '20px',
                zIndex: '4'
            }}>
                <img alt="music note" style={mobile ? {height: '50px'} : {height: '100px'}} src={MusicNote}/>
            </Box>
            <Box sx={ mobile ? {
                padding: '30px 20px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                zIndex: "3",
                position: 'absolute',
                top: "20%"
            } : {
                padding: '0px 0px 0px 130px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                zIndex: "3",
                position: 'absolute',
                top: "30%"
                }}>
                <Box sx={mobile ? {
                    fontSize: "60px", fontWeight: '400', lineHeight: '70px', textShadow: '2px 2px 4px #000000'
                } : {fontSize: "95px", fontWeight: '400', lineHeight: '110px', textShadow: '2px 2px 4px #000000'}}>
                    You must be a festival goer.
                </Box>
                <Box sx={mobile ? {
                    fontSize: "25px", fontWeight: '300', marginTop: "20px", marginBottom: "30px", textShadow: '2px 2px 4px #000000'
                } : {fontSize: "40px", fontWeight: '300', marginTop: "30px", marginBottom: "30px", maxWidth: '800px', textShadow: '2px 2px 4px #000000'}}>
                    Login with spotify to compare your listen history with various music festival lineups!
                </Box>
                <Box>
                    <a style={{textDecoration: 'none'}} href={loginUrl}>
                    <Button variant="outlined" color="secondary" size="large" startIcon={<SpoootifyIcon/>}>Login with Spotify</Button>
                    </a>
                </Box>
            </Box>
        </Box>
    )
}

export default Landing;