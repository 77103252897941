import axios from "axios";
const artists = {};

//goals
//get all artists from liked songs
//get all artists from playlists
//get all artists from recently listened to songs
//get all top artists from top listend - mid - and recent listened
// get all followed artists


const artistObject = {
	id: Number,
	name: String,
	instances: Number,
	instanceType: Array
}

const addToArtistsObj = (artist, type) => {
	if(!artists[artist.id]){
		artists[artist.id] = {
			id : artist.id,
			name : artist.name,
			instances: 1,
			instanceType: [type]
		}
	}else{
		artists[artist.id].instances++;
		if(artists[artist.id].instanceType.indexOf(type) === -1){
			artists[artist.id].instanceType.push(type)
		}
	}
}

const extractArtistsFromSaved = (tracks) => {
	tracks.map(track => {
		track.track.artists.map(artist => {
			addToArtistsObj(artist, "saved");
		})
	})
}

const extractArtistsFromFollowed = (followed) => {
	followed.artists.items.map(artist => {
		addToArtistsObj(artist, "followed");
	})
}

const extractRecentlyPlayed = (recent) => {
	recent.items.map(item => {
		item.track.artists.map(artist => {
			addToArtistsObj(artist, "recently played");
		})
	})
}

const getNextLiked = async (next, auth) => {
	let results = await axios({
		method: 'get',
		url: next,
		headers: {
			'Authorization': `Bearer ${auth}`
		}
	})

	extractArtistsFromSaved(results.data.items);

	if(results.data.next){
		getNextLiked(results.data.next, auth)
	}
}

const getNextFollowed = async (next, auth) => {
	let results = await axios({
		method: 'get',
		url: next,
		headers: {
			'Authorization': `Bearer ${auth}`
		}
	})

	extractArtistsFromFollowed(results.data);
	if(results.data.artists.next){
		getNextFollowed(results.data.artists.next, auth)
	}
}

//used for all top artist calls
const mapTopArtists = (results, type) => {
	results.items.map(artist => {
		addToArtistsObj(artist, type);
	})
}

//base function
export const getUserData = async (spotify, auth) => {
	const auth_token = auth;
	
	const likedSongs = await spotify.getMySavedTracks({limit: 50}).then((results) => {
		extractArtistsFromSaved(results.items);
		if(results.next){
			getNextLiked(results.next, auth_token)
		}
	}); //need to loop
	
	const recentlyPlayedTracks = await spotify.getMyRecentlyPlayedTracks({limit: 50}).then(results => extractRecentlyPlayed(results)); //seems like 50 is all there is
	
	// // easier to just manually do both calls than dynamically do the offset after each call, limit of 100 artists and we get them all with this
	const topArtistsLong = await spotify.getMyTopArtists({time_range: "long_term", limit: 50}).then(results => mapTopArtists(results, "long_term"));
	const topArtistsLong2 = await spotify.getMyTopArtists({time_range: "long_term", limit: 50, offset: 49}).then(results => mapTopArtists(results, "long_term"));
	const topArtistsMedium = await spotify.getMyTopArtists({time_range: "medium_term", limit: 50}).then(results => mapTopArtists(results, "medium_term"));
	const topArtistsMedium2 = await spotify.getMyTopArtists({time_range: "medium_term", limit: 50, offset: 49}).then(results => mapTopArtists(results, "medium_term"));
	const topArtistsShort = await spotify.getMyTopArtists({time_range: "short_term", limit: 50}).then(results => mapTopArtists(results, "short_term"));
	const topArtistsShort2 = await spotify.getMyTopArtists({time_range: "short_term", limit: 50, offset: 49}).then(results => mapTopArtists(results, "short_term"));
	
	const followedArtists = await spotify.getFollowedArtists({limit: 50}).then((results => {
		extractArtistsFromFollowed(results);
		if(results.artists.next){
			getNextFollowed(results.artists.next, auth_token);
		}
	})); //need to loop
	
	
	const playlists = await spotify.getUserPlaylists({limit: 50}); //need to loop


	//TODO: only 100 songs are returned at a time from a playlist, if there is more we need another request
	const playlistTracks = await Promise.all(playlists.items.map( async playlist => {
		return spotify.getPlaylist(playlist.id)
	}))

	playlistTracks.map(playlist => {
		playlist.tracks.items.map(item => {
			if(item.track){
				item.track.artists.map(artist => {
					addToArtistsObj(artist, "playlist");
				})
			}
		})
	})

	return artists;
}


export const getAllLineupArtists = async (spotify, auth, lineup) => {
    const artists = {};

	return lineup
}