import React from 'react';
import { Box, Button, Alert, AlertTitle, Collapse } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import { getUserData, getAllLineupArtists } from './api/spotifyCalls';
import SpotifyWebApi from 'spotify-web-api-js';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
// import lineups from './lineups/index';
import testFile from './lineups/testFile'
import LoadingOverlay from './components/LoadingOverlay';
import './styles/generatedBG.css';
import SpotifyLogo from './assets/Spotify_Logo_CMYK_White.png'

const spotify = new SpotifyWebApi();

function Results() {
	let navigate = useNavigate();
	const keepClosed = localStorage.getItem("keepClosed");
	const storedData = localStorage.getItem("data");
	const sessionExpiration = localStorage.getItem("expiration");
	const token = localStorage.getItem("token")
	const compareLineup = localStorage.getItem("lineup");
	const mobile = useMediaQuery('(max-width:600px)');
	const [spotifyToken, setSpotifyToken] = useState("");
	const [data, setData] = useState({});
	const [lineupData, setLineupData] = useState({});
	const [matchingData, setMatchingData] = useState({});
	const [loading, setLoading] = useState(true);
	const [infoOpen, setInfoOpen] = useState(true);
	// const storedToken = localStorage.getItem("token");

	// manages setting the token either from local storage or from the URL
	useEffect(() => {

		//forward the user to home if token is expired
		if (sessionExpiration && Date.now() > sessionExpiration) {
			localStorage.removeItem("expiration");
			localStorage.removeItem("data");
			localStorage.removeItem("token");
			navigate("/", { replace: true });
			return;
		}

		if (token && !storedData) {
			setSpotifyToken(token)
		}

		if (storedData) {
			setData(JSON.parse(storedData))
			setLoading(false)
		}
	}, [])

	// gets data from token
	useEffect(() => {
		if (spotifyToken) {
			spotify.setAccessToken(spotifyToken)
			getUserData(spotify, spotifyToken).then(res => {
				setData(res);
				setLoading(false)
				localStorage.setItem("data", JSON.stringify(res))
			})
			getAllLineupArtists(spotify, spotifyToken, testFile[compareLineup]).then(res => {
				setLineupData(res)
				localStorage.setItem("lineupData", JSON.stringify(res))
			})
		}
	}, [spotifyToken])

	useEffect(() => {
		if (data) {
			const matches = {};
			const ref = testFile[compareLineup].lineup
			ref.map(lineup => {
				if (data[lineup.id]) {
					// matches.push(data[lineup.id])
					matches[lineup.id] = data[lineup.id]
				}
			})
			setMatchingData(matches);
		}
	}, [data])

	const getHover = (instanceType) => {
		return instanceType.join(', ').replace(/_/g, ' ')
	}

	const base = {
		// fontSize: '40px',
		margin: '10px 5px',
		padding: '2px 15px',
		// cursor: 'default',
		fontFamily: 'Oswald, sans-serif',
		fontWeight: '500',
		color: 'white',
	}

	const getInfoOpen = () => {
		if(keepClosed){
			return false
		}else{
			return infoOpen
		}
	}

	return (
		<div>
			<LoadingOverlay isOpen={loading} />
			<Collapse in={getInfoOpen()}>
				<Alert sx={{ maxWidth: "500px", margin: "auto", marginTop: "20px" }} variant="outlined" onClose={() => { setInfoOpen(false) }} severity="info">
					<AlertTitle>Info</AlertTitle>
					{!mobile && <span><strong>Hover </strong> on each highlighted box to see how you know each artist!</span>}

					<Box sx={{ marginTop: "10px" }}>
						<strong>Guide:</strong>
						<div>short term - played in the last 4 weeks</div>
						<div>medium term - 6 months</div>
						<div>long term - years</div>
					</Box>
					<Button sx={{ marginTop: '10px' }} color="info" variant="outlined" onClick={(() => {
						localStorage.setItem("keepClosed", true)
						setInfoOpen(false)
					})}>Don't Show Again</Button>
				</Alert>
			</Collapse>
				<Box className="bg" style={{display: 'flex', flexDirection: 'column', boxShadow: 'rgb(80 80 80 / 50%) 0px 0px 15px, rgb(255 255 255) 0px 0px 5px inset', borderRadius: '5px', margin: 'auto', marginTop: '20px', maxWidth: '800px', padding: '50px 0px' }}>
					<Box style={{display: 'flex', alignItems: 'flex-start'}}>
						{/* <Box style={{color: 'white', paddingRight: '10px'}}>Data Provided by </Box> */}
						<img style={{height: "40px"}} src={SpotifyLogo}/>
					</Box>
					{mobile ? <Box style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center'}}>
						{testFile[compareLineup].lineup.map(artist =>
							matchingData[artist.id] ?
								<a target="_blank" style={{textDecoration: 'none'}} href={"https://open.spotify.com/artist/" + artist.id}>
									<Box style={{ backgroundColor: 'rgba(56, 81, 119, 0.3)', borderRadius: '5px', boxShadow: '0px 0px 5px #333, inset 0px 0px 2px #333', fontSize: '30px', ...base }}>
										<Box>
											{artist.artist}
										</Box>
										<Box style={{fontSize: "14px"}}>
											{getHover(matchingData[artist.id].instanceType)}
										</Box>
									</Box>
								</a>
								:
								<a target="_blank" style={{textDecoration: 'none'}} href={"https://open.spotify.com/artist/" + artist.id}>
									<Box style={{ fontSize: '30px', ...base }} key={artist.artist}>{artist.artist}</Box>
								</a>
						)}
					</Box> :
					<Box style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
						{testFile[compareLineup].lineup.map(artist =>
							matchingData[artist.id] ?
								<Tooltip enterDelay={200} leaveDelay={200} followCursor key={artist.artist} title={getHover(matchingData[artist.id].instanceType)} placement="top-start">
									<a target="_blank" style={{textDecoration: 'none'}} href={"https://open.spotify.com/artist/" + artist.id}><Box style={{ backgroundColor: 'rgba(56, 81, 119, 0.3)', borderRadius: '5px', boxShadow: '0px 0px 5px #333, inset 0px 0px 2px #333', fontSize: '40px', ...base }}>{artist.artist}</Box></a>
								</Tooltip>
								:
								<a target="_blank" style={{textDecoration: 'none'}} href={"https://open.spotify.com/artist/" + artist.id}>
									<Box style={{ fontSize: '40px', ...base }} key={artist.artist}>{artist.artist}</Box>
								</a>
						)}
					</Box>}
				</Box>
			<Box sx={{display: 'flex', justifyContent: 'center'}}>
				<Button variant="contained" sx={{ m: 2 }} onClick={() => navigate("/lineups", { replace: true })}>Try another</Button>
			</Box>
		</div>
	)
}

export default Results;