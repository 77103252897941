import React, { useEffect } from 'react';
// import AdSense from 'react-adsense';
import { getTokenFromUrl } from './api/spotify';
// import { UserContext } from './App';
import { useNavigate } from "react-router-dom";
// import lineups from './lineups/index';
import testFile from './lineups/testFile'
import LineupButton from './components/LineupButton';
import Box from '@mui/material/Box';

const authEndpoint = 'https://accounts.spotify.com/authorize';
const redirectUri = 'https://lineuplens.com/lineups';
// const redirectUri = 'http://localhost:8888/lineups'
const client_id = '74fedf76b5ea469d936cdae4bf6ebca9'; // Your client id
let scopes = 'user-read-recently-played user-library-read user-top-read user-follow-read playlist-read-private';

const loginUrl = `${authEndpoint}?
client_id=${client_id}
&redirect_uri=${redirectUri}
&scope=${scopes}
&response_type=token
&show_dialog=true`



function Home() {
	let navigate = useNavigate();
	const sessionExpiration = localStorage.getItem("expiration");
	
	useEffect(() => {
		if(sessionExpiration && Date.now() > sessionExpiration){
			localStorage.removeItem("expiration");
			localStorage.removeItem("data");
			localStorage.removeItem("token");
		}
	})

		// manages setting the token either from local storage or from the URL
		useEffect(() => {
			const token = getTokenFromUrl().access_token;
			window.location.hash = "";
	
			//forward the user to home if token is expired
			if (sessionExpiration && Date.now() > sessionExpiration) {
				localStorage.removeItem("expiration");
				localStorage.removeItem("data");
				localStorage.removeItem("token");
				navigate("/", { replace: true });
				return;
			}
	
			if (token) {
				localStorage.setItem("token", token);
				localStorage.setItem("expiration", Date.now() + 3600000)
				return;
			}
		})
	
	const getButton = (lineup) => {
		if(localStorage.getItem("token")){
			return (
				<LineupButton lineup={testFile[lineup]} onClick={() => {
					localStorage.setItem("lineup", lineup)
					navigate("/results", { replace: true });
					}}/>
			)
		}else{
			return (
				<a href={loginUrl}><LineupButton lineup={testFile[lineup]} onClick={() => {
					localStorage.setItem("lineup", lineup)
					}}/></a>
			)
		}
	}

	return(
		<div className="App">
			<Box sx={{m: 2, fontFamily: 'Oswald, sans-serif', fontSize: "25px"}}>Pick a lineup to see who you have listened to before on Spotify!!</Box>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					flexWrap: 'wrap'
					}}>
					{Object.keys(testFile).map(lineup => 
						<Box sx={{m: 1}} key={lineup}>
							{getButton(lineup)}
						</Box>
					)}
				</Box>
    	</div>
	)
}

export default Home;