import React from 'react';
import Box from '@mui/material/Box';
// import freaky from '../lineups/freakyDeaky/7ddac06d-foreground.png'

export default function LineupButton({onClick, lineup}) {

    return(
        <Box sx={{
            // border: '2px red solid',
            width: '250px',
            height: '200px',
            backgroundSize: 'cover',
            backgroundImage: `url(${lineup.image})`,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            cursor:'pointer'
        }}
        onClick={onClick}
        >
            <Box sx={{
                fontFamily: 'Oswald, sans-serif',
                fontSize: "20px",
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                borderRadius: '3px',
                color: 'white', 
                marginBottom: '10px',
                padding: '5px 15px',}}
            >{lineup.name}</Box>
        </Box>
    )
}