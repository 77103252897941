
import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import Home from './Home';
import Landing from './Landing';
import Results from './Results';
import HeaderFooter from './HeaderFooter';
import Test from './Test';

import {
	BrowserRouter,
	Routes,
	Route,
	Navigate
} from "react-router-dom";

let theme = createTheme({
	palette: {
		primary: {
			main: '#751d85',
		},
		secondary: {
			main: '#fff'
		},
		background: {
			// default: '#751d85'
		},
	},
});

export const UserContext = React.createContext();

let loggedIn = localStorage.getItem("token")

function App() {
	const state = useState('')

	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<UserContext.Provider value={state}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<HeaderFooter>
						<Routes>
							<Route path='/' element={loggedIn ? <Home/> : <Landing />} />
							{/* <Route path='/lineups' element={loggedIn ? <Home/> : <Navigate to='/'/>} /> */}
							<Route path='/lineups' element={<Home/>} />
							<Route path='/results' element={<Results />} />
							<Route path='/test' element={<Test />} />
						</Routes>
					</HeaderFooter>
				</ThemeProvider>
			</UserContext.Provider>
		</BrowserRouter>
	);
}

export default App;
