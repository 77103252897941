
import React from 'react';

function Test() {

	return (
		<div>test page</div>
	);
}

export default Test;